import { PickerProps } from 'emoji-mart';
import customEmojis from './emojis.json';

const getEmojiConfig = () => {
  const config: PickerProps = {
    perLine: 9,
    theme: 'light',
    emoji: 'point_up',
    title: 'Pick your emoji…',
    custom: []
  };

  customEmojis.forEach((customEmoji) => {
    config.custom?.push({
      id: customEmoji.name,
      name: customEmoji.name,
      short_names: [customEmoji.name],
      emoticons: [],
      keywords: customEmoji.credit ? [customEmoji.credit] : undefined,
      imageUrl: customEmoji.image_url
    });
  });

  return config;
};

export const suggestionList = customEmojis.map((ce) => {
  return {
    ...ce,
    name: ':' + ce.name + ':',
    avatar: ce.image_url
  };
});

export const parseCustomEmoji = (shortcut: string): string | undefined => {
  const trimShortcut = shortcut.replace(/^:+|:+$/g, '');
  const emoji = customEmojis.find((customEmoji) => customEmoji.name === trimShortcut);
  if (emoji) {
    return emoji.image_url;
  }

  return undefined;
};

export default getEmojiConfig();
